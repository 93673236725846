import { useSelector } from "@xstate/react";
import * as React from "react";
import { useContext } from "react";
import { DDProJoinStateContext } from "./DDProJoinStateProvider";
import { ProJoinMachineState } from "./machine";

const errorMessageSelector = (s: ProJoinMachineState) => s.context.errorMessage;

export function JoinError() {
  const pro = useContext(DDProJoinStateContext);
  const errorMessage = useSelector(pro.machine!, errorMessageSelector);

  return (
    <div className="flex flex-col items-center min-h-screen">
      <svg width="87" height="88" viewBox="0 0 87 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M56.1875 39.8994C59.1905 39.8994 61.625 37.465 61.625 34.4619C61.625 31.4589 59.1905 29.0244 56.1875 29.0244C53.1845 29.0244 50.75 31.4589 50.75 34.4619C50.75 37.465 53.1845 39.8994 56.1875 39.8994Z"
          fill="#B7C2D7"
        />
        <path
          d="M30.8125 39.8994C33.8155 39.8994 36.25 37.465 36.25 34.4619C36.25 31.4589 33.8155 29.0244 30.8125 29.0244C27.8095 29.0244 25.375 31.4589 25.375 34.4619C25.375 37.465 27.8095 39.8994 30.8125 39.8994Z"
          fill="#B7C2D7"
        />
        <path
          d="M43.5 50.7744C35.0538 50.7744 27.84 56.0307 24.94 63.4619H30.9938C33.495 59.1482 38.135 56.2119 43.5 56.2119C48.865 56.2119 53.4688 59.1482 56.0063 63.4619H62.06C59.16 56.0307 51.9463 50.7744 43.5 50.7744ZM43.4638 7.27441C23.4538 7.27441 7.25 23.5144 7.25 43.5244C7.25 63.5344 23.4538 79.7744 43.4638 79.7744C63.51 79.7744 79.75 63.5344 79.75 43.5244C79.75 23.5144 63.51 7.27441 43.4638 7.27441ZM43.5 72.5244C27.4775 72.5244 14.5 59.5469 14.5 43.5244C14.5 27.5019 27.4775 14.5244 43.5 14.5244C59.5225 14.5244 72.5 27.5019 72.5 43.5244C72.5 59.5469 59.5225 72.5244 43.5 72.5244Z"
          fill="#B7C2D7"
        />
      </svg>
      <h1 className="font-normal text-4xl m-0 my-4">Oops!</h1>
      <p className="text-lg px-4 text-center mb-6">{errorMessage}</p>
    </div>
  );
}
