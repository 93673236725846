import { signInWithCustomToken } from "firebase/auth";
import toast from "react-hot-toast";
import { assign, createMachine, DoneInvokeEvent, MachineConfig, State } from "xstate";
import CreateUser from "../../../graphql/CreateUser.graphql";
import AddMemberWithGroupId from "../../../graphql/DDPro/AddMemberWithGroupId.graphql";
import JoinGroup from "../../../graphql/DDPro/JoinGroup.graphql";
import GetSubscriptionInfo from "../../../graphql/GetSubscriptionInfo.graphql";
import { HomePage, NewMemberPersonalizationPage, PaymentPage } from "../../../pages";
import { Locale } from "../../../types/Locale";
import { client } from "../../../utils/apollo-client";
import { auth } from "../../../utils/auth";
import { useNavigate } from "../../../components/Link/Link";
import { IDDProLanguage } from "../pro/types";
import { useDDProTranslations } from "../useDDProTranslations";
import { getUrl } from "./utils";

export type ProJoinMachineState = State<
  ProJoinMachineContext,
  ProJoinMachineEvents,
  any,
  {
    value: any;
    context: ProJoinMachineContext;
  }
>;

export type MessagePayload = {
  name?: string;
  group_title?: string;
  create_time?: string;
  expire_time?: string;
  url_host_path?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  subscription_payer?: 1 | 2; // 1: Admin - 2: Member
  language?: IDDProLanguage;
};

export type JoinGroupFromEmailPayload = {
  signedURL: string;
  firstName: string;
  lastName: string;
  password: string;
  taxResidence: string;
};

type JoinGroupPayload = {
  groupId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type CreateUserPayload = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  taxResidence: string;
  language: string;
  communicationPrefs: {
    subscribeMemberEmails: boolean;
    subscribeNewsletterEmails: boolean;
  };
};
export interface ProJoinMachineContext {
  locale: Locale;
  signedUrl?: string;
  payload?: MessagePayload;
  notification?: string;
  errorMessage?: string;
}

export type ProJoinMachineEvents = CreateUserEvent | AddToGroupEvent | ContinueEvent;

type AddToGroupEvent = { type: "ADD_TO_GROUP"; payload: JoinGroupFromEmailPayload };
type CreateUserEvent = { type: "CREATE_USER"; payload: CreateUserPayload };
type ContinueEvent = { type: "CONTINUE" };
type DoneInvokeEventCreateUser = DoneInvokeEvent<{
  data: {
    createUser: { token: string; firstName: string; lastName: string; email: string };
  };
}>;
type DoneInvokeEventJoinGroup = DoneInvokeEvent<{
  data: { joinDDProGroup: { user: { token: string } } };
}>;

const loginState = (loginService: string) => ({
  entry: ["notifyLogInMember"],
  invoke: {
    src: loginService,
    onError: {
      actions: ["notifyError"],
      target: "form",
    },
    onDone: "checkingSubscription",
  },
});

const checkSubscriptionState = (onDone: string) => ({
  entry: ["notifyCheckingSubscription"],
  invoke: {
    src: "checkSubscription",
    onError: {
      actions: ["notifyError"],
      target: "form",
    },
    onDone,
  },
});

const machineConfig = (
  locale: Locale,
  signedUrl?: string,
  payload?: MessagePayload
): MachineConfig<ProJoinMachineContext, any, ProJoinMachineEvents> => ({
  initial: "check",
  id: "main",
  context: {
    locale,
    signedUrl,
    payload,
  },
  states: {
    check: {
      always: [
        { target: "fromEmail", cond: "hasFromEmailPayload" },
        { target: "fromQr", cond: "hasFromQRPayload" },
        { target: "error", actions: [(e) => console.log("error", e), "setInvalidInvitationError"] },
      ],
    },
    error: {},
    fromEmail: {
      initial: "form",
      states: {
        form: {
          on: {
            ADD_TO_GROUP: {
              target: "addingToGroup",
            },
          },
        },
        addingToGroup: {
          entry: ["notifyAddingMember"],
          invoke: {
            src: "addMember",
            onError: {
              actions: ["notifyError"],
              target: "form",
            },
            onDone: "login",
          },
        },
        login: loginState("logInAfterJoiningGroup"),
        checkingSubscription: checkSubscriptionState("toNextStep"),
        toNextStep: {
          always: [
            {
              cond: "isAdminPays",
              target: "toHomePage",
            },
            {
              target: "toPaymentPage",
            },
          ],
        },
        toHomePage: {
          entry: ["toHome"],
        },
        toPaymentPage: {
          entry: ["toPayment"],
        },
      },
    },
    fromQr: {
      initial: "form",
      states: {
        form: {
          on: {
            CREATE_USER: "creatingUser",
          },
        },
        creatingUser: {
          entry: ["notifyAddingMember"],
          invoke: {
            src: "creatingUser",
            onError: {
              actions: ["notifyError"],
              target: "#main.error",
            },
            onDone: { target: "login", actions: ["storeUser", "logResult"] },
          },
        },
        login: loginState("logInAfterCreatingUser"),
        checkingSubscription: checkSubscriptionState("addingToGroup"),
        addingToGroup: {
          entry: ["notifyAddingMember"],
          invoke: {
            src: "addMemberWithGroupId",
            onError: {
              actions: ["setJoinError", "notifyError"],
              target: "#main.error",
            },
            onDone: { actions: ["logResult", "removeNotification", "toPayment"] },
          },
        },
      },
    },
  },
});

export const machine = ({
  locale,
  payload,
  tt,
  navigate,
}: {
  locale: Locale;
  payload?: MessagePayload;
  tt: ReturnType<typeof useDDProTranslations>;
  navigate: ReturnType<typeof useNavigate>;
}) => {
  return createMachine<ProJoinMachineContext, ProJoinMachineEvents>(
    machineConfig(locale, getUrl(payload?.url_host_path ?? ""), payload),
    {
      actions: {
        logResult: (_, e) => console.log(e),
        setJoinError: assign({
          errorMessage: (_, event) => {
            const msg = (event as DoneInvokeEvent<any>).data.message;
            const errorGroupAdmin = /graphql: subscriptions are paid by the group admin/;
            const errorExistingUser = /existing user/;
            return errorGroupAdmin.test(msg)
              ? tt.subscriptionsArePaidByTheGroupAdminThereforeAddingMembersIsNotAllowedPleaseContactYourDdProAdministrator
              : errorExistingUser.test(msg)
              ? tt.addingAnExistingUserWithAnExistingSubscriptionIsNotAllowed
              : tt.unknownErrorPleaseContactYourDdProAdministrator;
          },
        }),
        setInvalidInvitationError: () =>
          assign({
            errorMessage: tt.theInvitationUrlYouveEnteredIsInvalidPleaseContactYourDdProAdministrator,
          }),
        toSurvey: () => {
          navigate({ to: NewMemberPersonalizationPage });
        },
        toPayment: () => {
          navigate({ to: PaymentPage, query: { content: "ddpro" } });
        },
        toHome: () => {
          navigate({ to: HomePage });
        },
        notifyAddingMember: assign({
          notification: (_) => toast.loading(tt.adding), // eslint-disable-line @typescript-eslint/no-unused-vars
        }),
        notifyLogInMember: assign({
          notification: (ctx) =>
            toast.loading(tt.login, {
              id: ctx.notification,
            }),
        }),
        notifyError: (ctx, event) => {
          const res = (event as DoneInvokeEvent<string>).data;
          const isAlreadyRegistered = /email already registered/.test(res);
          const isSignedURLInvalid = /join signed URL appears to be invalid/.test(res);

          const toastError = (msg: string) =>
            toast.error(msg, {
              id: ctx.notification,
            });

          if (isSignedURLInvalid) {
            toastError(tt.theLinkToJoinAppearsToBeInvalid);
          } else if (isAlreadyRegistered) {
            toastError(tt.thisMemberAppearsToBeAlreadyRegistered);
          } else {
            toastError(tt.oopsCantPerformThisAction);
          }
        },
        notifyAddMemberSuccess: (ctx) =>
          toast?.success(tt.done, {
            id: ctx.notification,
          }),
        notifyCheckingSubscription: assign({
          notification: (ctx) =>
            toast.loading(tt.checkingSubscription, {
              id: ctx.notification,
            }), // eslint-disable-line @typescript-eslint/no-unused-vars
        }),
        removeNotification: () => toast.dismiss(),
        storeUser: assign((ctx, e) => {
          const { data } = (e as DoneInvokeEventCreateUser).data;
          const { email, firstName, lastName } = data.createUser;
          return {
            ...ctx,
            payload: {
              ...ctx.payload,
              email,
              first_name: firstName,
              last_name: lastName,
            },
          };
        }),
      },
      services: {
        creatingUser: (_, event) =>
          client.mutate({
            mutation: CreateUser,
            variables: {
              ...(event as CreateUserEvent).payload,
            },
          }),
        addMember: (_, event) =>
          client.mutate({
            mutation: JoinGroup,
            variables: {
              input: (event as AddToGroupEvent).payload,
            },
          }),
        addMemberWithGroupId: (ctx) =>
          client.mutate({
            mutation: AddMemberWithGroupId,
            variables: {
              input: {
                ...({
                  email: ctx.payload?.email,
                  firstName: ctx.payload?.first_name,
                  lastName: ctx.payload?.last_name,
                  groupId: ctx.payload?.name,
                } as JoinGroupPayload),
              },
            },
          }),
        logInAfterJoiningGroup: (_, event) => {
          const { data } = (event as DoneInvokeEventJoinGroup).data;
          const customToken = data.joinDDProGroup.user.token;
          return signInWithCustomToken(auth, customToken);
        },
        logInAfterCreatingUser: (_, event) => {
          const { data } = (event as DoneInvokeEventCreateUser).data;
          const customToken = data.createUser.token;
          return signInWithCustomToken(auth, customToken);
        },
        checkSubscription: () => client.query({ query: GetSubscriptionInfo }).then((res) => res.data),
      },
      guards: {
        hasSubscription: (_, e) =>
          (e as DoneInvokeEvent<{ membershipSubscription: any }>).data.membershipSubscription !== null,
        hasFromEmailPayload: (ctx) => typeof ctx.payload?.email !== "undefined",
        hasFromQRPayload: (ctx) =>
          typeof ctx.payload?.group_title !== "undefined" && typeof ctx.payload?.name !== "undefined",
        isAdminPays: (ctx) => ctx.payload?.subscription_payer === 1,
      },
    }
  );
};
