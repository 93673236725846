import { ErrorMessage, FieldInputProps, FormikProps } from "formik";
import * as React from "react";
import { countryCodes } from "../../../utils/countryCodes";
import { useDDProTranslations } from "../useDDProTranslations";

export const CountrySelect = ({ field, ...props }: { field: FieldInputProps<string> } & FormikProps<{}>) => {
  const tt = useDDProTranslations();
  return (
    <div className="flex flex-col">
      <div className="flex items-baseline justify-between">
        <label title={tt.taxResidence} htmlFor={field.name} className="font-medium py-2 text-base">
          {tt.country}
        </label>
        <ErrorMessage name={field.name} component="div" className="font-medium text-pink text-xs" />
      </div>
      <select tabIndex={0} className="bg-lighter-grey mb-4 px-4 py-3 rounded-lg w-full" {...field} {...props}>
        <option value={undefined}></option>
        {countryCodes.map(([code, name], idx) => (
          <option key={idx} value={code}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};
