import { useActor, useSelector } from "@xstate/react";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import * as yup from "yup";
import useGeoCountryCode from "../../../graphql/useGeoCountryCode/useGeoCountryCode";
import { LoginPage } from "../../../pages";
import { Locale } from "../../../types/Locale";
import { Identity } from "../../../utils/fp";
import { Link } from "../../../components/Link/Link";
import { ActionButton } from "../form/ActionButton";
import { CountrySelect } from "../form/CountrySelect";
import { EmailInput } from "../form/EmailInput";
import { FirstNameInput } from "../form/FirstNameInput";
import { LastNameInput } from "../form/LastNameInput";
import { PasswordInput } from "../form/PasswordInput";
import { useDDProTranslations } from "../useDDProTranslations";
import { DDProJoinStateContext } from "./DDProJoinStateProvider";
import { CreateUserPayload, ProJoinMachineState } from "./machine";

export type JoinFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  taxResidence?: string;
};

const validationSchema = yup.object({
  firstName: yup.string().trim().min(1, "First name cannot be empty").required("Required"),
  lastName: yup.string().trim().min(1, "Last name cannot be empty").required("Required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().min(6, "The password must be at least 6 characters").required("Required"),
  taxResidence: yup.string().min(0).required("Required"),
});

const formInitialValues = (geoCountryCode: string | null): JoinFormValues => ({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  taxResidence: geoCountryCode ?? undefined,
});

const withDefaultCommunicationPref = (obj: Partial<CreateUserPayload>) => ({
  ...obj,
  communicationPrefs: {
    subscribeMemberEmails: false,
    subscribeNewsletterEmails: false,
  },
});

const withLocale = (locale: Locale) => (obj: Partial<CreateUserPayload>) => ({
  ...obj,
  language: locale,
});

const withFormValues = (values: JoinFormValues) => (obj: Partial<CreateUserPayload>) => ({
  ...obj,
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  password: values.password,
  taxResidence: values.taxResidence!,
});

const isBusySelector = (s: ProJoinMachineState) => !s.matches({ fromQr: "form" });
const localeSelector = (state: ProJoinMachineState) => state.context.locale;
const groupTitleSelector = (state: ProJoinMachineState) =>
  decodeURIComponent(state.context.payload?.group_title ?? "");
const subscriptionPayerSelector = (state: ProJoinMachineState) => state.context.payload?.subscription_payer;

export function JoinFromQRNoEmail() {
  const pro = useContext(DDProJoinStateContext);
  const [, send] = useActor(pro.machine!);
  const isBusy = useSelector(pro.machine!, isBusySelector);
  const { data: geoCountryCode } = useGeoCountryCode();
  const locale = useSelector(pro.machine!, localeSelector);
  const groupTitle = useSelector(pro.machine!, groupTitleSelector);
  const subscriptionPayer = useSelector(pro.machine!, subscriptionPayerSelector);
  const isMemberPays = useMemo(() => subscriptionPayer === 2, [subscriptionPayer]);
  const initialValues = useMemo(() => formInitialValues(geoCountryCode), [geoCountryCode]);
  const tt = useDDProTranslations();
  const onSubmitHandler = useCallback(
    (values: JoinFormValues) => {
      const payload = Identity.of({})
        .map(withDefaultCommunicationPref)
        .map(withLocale(locale))
        .fold(withFormValues(values)) as CreateUserPayload;

      send({ type: "CREATE_USER", payload });
    },
    [send, locale]
  );

  return (
    <div className="w-full md:w-1/2 mx-auto px-6">
      <div className="font-medium text-sm tracking-wider">{tt.stepXofY("1", "3")}</div>
      <h1 className="font-normal m-0 mb-4 text-4xl tracking-tight">{tt.startYourFreeTrial}</h1>
      <p className="font-normal m-0 mb-4 text-lg tracking-tight">
        {tt.joinDietDoctorInCollaborationWith(groupTitle)}
      </p>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        <Form>
          <Field type="text" name="firstName" component={FirstNameInput} autoFocus />
          <Field type="text" name="lastName" component={LastNameInput} />
          <Field type="email" name="email" component={EmailInput} />
          <Field name="password" component={PasswordInput} />
          {isMemberPays && <Field as="select" name="taxResidence" component={CountrySelect} />}
          <div className="flex flex-col lg:flex-row mt-4">
            <ActionButton type="submit" label={tt.startNow} busy={isBusy} />
          </div>
        </Form>
      </Formik>
      <p className="m-0 p-0 mt-2 text-base text-center md:text-left">
        {tt.alreadyHaveAnAccount}{" "}
        <Link to={LoginPage} className="text-green underline">
          {tt.signInHere}
        </Link>
      </p>
    </div>
  );
}
