import { useInterpret } from "@xstate/react";
import React, { createContext, useContext } from "react";
import { InterpreterFrom, StateMachine } from "xstate";
import { createExternalPage } from "../../../page-utils";
import { DDProJoinPage as PageDefinition, GetPageContext, localePrefix } from "../../../pages";
import { Locale } from "../../../types/Locale";
import { tryOrUndefined } from "../../../utils/utils";
import { useNavigate } from "../../../components/Link/Link";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { IDDProLanguage } from "../pro/types";
import { useDDProTranslations } from "../useDDProTranslations";
import { machine, MessagePayload, ProJoinMachineContext, ProJoinMachineEvents } from "./machine";
import { b64_to_utf8, ddProLanguageToLocale, localeToDDProLanguage } from "./utils";

type DDProJoinPageContext = GetPageContext<typeof PageDefinition>;

export const DDProJoinStateContext = createContext<{
  machine?: InterpreterFrom<
    StateMachine<
      ProJoinMachineContext,
      any,
      ProJoinMachineEvents,
      {
        value: any;
        context: ProJoinMachineContext;
      }
    >
  >;
}>({});

export const DDProJoinStateProvider: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const { query, locale } = useContext<DDProJoinPageContext>(pageContext);
  const { m: message } = query ?? { m: undefined };
  const tt = useDDProTranslations();
  const navigate = useNavigate();

  const decoded = b64_to_utf8(message ?? "");
  const payload = tryOrUndefined<MessagePayload>(() => JSON.parse(decoded!));

  const joinMachine = useInterpret(machine({ locale, payload, tt, navigate }));
  joinMachine.start();

  const langToDisplay = payload?.language || IDDProLanguage.EN;

  // by default, all join templates goes to the English version of the form
  // we then check in the payload message if a different language should be
  // displayed, if that is the case, we do a redirection
  if (langToDisplay !== localeToDDProLanguage(locale)) {
    navigate({
      to: JoinPage,
      query: { nextLocale: ddProLanguageToLocale(langToDisplay), search: window.location.search },
    });
    return null;
  }

  return (
    <DDProJoinStateContext.Provider value={{ machine: joinMachine }}>
      {props.children}
    </DDProJoinStateContext.Provider>
  );
};

const JoinPage = createExternalPage<undefined, { nextLocale: Locale; search: string }>({
  name: "join",
  path: (_1, _2, { nextLocale, search }) => `${localePrefix[nextLocale]}/pro/join${search}`,
});
