import { useActor, useSelector } from "@xstate/react";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useContext, useMemo } from "react";
import * as yup from "yup";
import useGeoCountryCode from "../../../graphql/useGeoCountryCode/useGeoCountryCode";
import { ActionButton } from "../form/ActionButton";
import { CountrySelect } from "../form/CountrySelect";
import { FirstNameInput } from "../form/FirstNameInput";
import { LastNameInput } from "../form/LastNameInput";
import { PasswordInput } from "../form/PasswordInput";
import { useDDProTranslations } from "../useDDProTranslations";
import { DDProJoinStateContext } from "./DDProJoinStateProvider";
import { JoinGroupFromEmailPayload, ProJoinMachineState } from "./machine";

const isBusySelector = (s: ProJoinMachineState) => !s.matches({ fromEmail: "form" });
const payloadSelector = (state: ProJoinMachineState) => state.context.payload!;
const signedURLSelector = (state: ProJoinMachineState) => state.context.signedUrl;

const validationSchema = yup.object({
  firstName: yup.string().trim().min(1, "First name cannot be empty").required("Required"),
  lastName: yup.string().trim().min(1, "Last name cannot be empty").required("Required"),
  password: yup.string().min(6, "The password must be at least 6 characters").required("Required"),
  taxResidence: yup.string().min(0).required("Required"),
});

export function JoinFromEmail() {
  const pro = useContext(DDProJoinStateContext);
  const [, send] = useActor(pro.machine!);
  const isBusy = useSelector(pro.machine!, isBusySelector);
  const signedURL = useSelector(pro.machine!, signedURLSelector);
  const { data: geoCountryCode } = useGeoCountryCode();
  const { first_name, last_name, group_title, email, subscription_payer } = useSelector(
    pro.machine!,
    payloadSelector
  );
  const isMemberPays = useMemo(() => subscription_payer === 2, [subscription_payer]);
  const tt = useDDProTranslations();
  return (
    <div className="w-full md:w-1/2 mx-auto px-6">
      <h1
        className="font-normal text-4xl m-0 mb-4"
        dangerouslySetInnerHTML={{ __html: tt.xWelcomesYouToDietDoctor(group_title!) }}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: first_name ?? "",
          lastName: last_name ?? "",
          password: "",
          taxResidence: geoCountryCode ?? undefined,
          email,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const payload: JoinGroupFromEmailPayload = {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            taxResidence: values.taxResidence!,
            signedURL: signedURL!,
          };
          send({ type: "ADD_TO_GROUP", payload });
        }}
      >
        <Form>
          <Field type="text" name="firstName" component={FirstNameInput} autoFocus />
          <Field type="text" name="lastName" component={LastNameInput} />
          <Field name="password" component={PasswordInput} />
          {isMemberPays && <Field as="select" name="taxResidence" component={CountrySelect} />}
          <div className="flex flex-col lg:flex-row mt-4">
            <ActionButton type="submit" label={tt.startNow} busy={isBusy} />
          </div>
        </Form>
      </Formik>
    </div>
  );
}
