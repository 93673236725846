import React from "react";
import DDplusIcon from "../../../icons/DDplusIcon";

export const NavBar = () => {
  return (
    <div className="flex py-4 justify-center border-b border-lighter-grey">
      <DDplusIcon />
    </div>
  );
};
