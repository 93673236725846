import { useField, FieldInputProps, FormikProps } from "formik";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { MdVisibility } from "@react-icons/all-files/md/MdVisibility";
import { MdVisibilityOff } from "@react-icons/all-files/md/MdVisibilityOff";
import { useDDProTranslations } from "../useDDProTranslations";

export const PasswordInput = ({
  title = "",
  ...props
}: { field: FieldInputProps<string>; title: string } & FormikProps<{}>) => {
  const [field, meta] = useField("password");
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = useCallback(() => setShowPassword(true), [setShowPassword]);
  const handleHidePassword = useCallback(() => setShowPassword(false), [setShowPassword]);
  const tt = useDDProTranslations();
  const hasTitle = useMemo(() => title !== "", [title]);
  return (
    <div className="flex flex-col">
      <div className="flex items-baseline justify-between">
        {hasTitle ? (
          <label htmlFor={field.name} className="font-medium py-2 text-base">
            {tt.password}
          </label>
        ) : (
          <div />
        )}
      </div>

      <div className="relative">
        <input
          id="new-password"
          placeholder={!hasTitle ? tt.password : ""}
          tabIndex={0}
          className={`${
            meta.touched && meta.error ? "border-pink border-2" : "border-grey border"
          } focus:bg-white transition-all focus:ring-2 focus:ring-grey focus:shadow-md mb-4 px-4 py-3 rounded-lg w-full flex-1 appearance-none border placeholder-grey focus:outline-none focus:border-transparent`}
          autoComplete="new-password"
          aria-describedby="password-constraints"
          {...field}
          {...props}
          type={showPassword ? "text" : "password"}
        />
        <button
          className="appearance-none m-0 p-0 absolute right-3 top-4"
          type="button"
          aria-label={tt.showPasswordAsPlainTextWarningThisWillDisplayYourPasswordOnTheScreen}
        >
          {!showPassword && <MdVisibility size={24} onClick={handleShowPassword} />}
          {showPassword && <MdVisibilityOff size={24} onClick={handleHidePassword} />}
        </button>
      </div>
    </div>
  );
};
