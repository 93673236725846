export class Identity<T> {
  value: T;
  constructor(value: T) {
    this.value = value;
  }

  static of<T>(value: T) {
    return new Identity(value);
  }

  map(f: Function): Identity<T> {
    return Identity.of(f(this.value));
  }

  fold(f: Function): T {
    return f(this.value);
  }
}
