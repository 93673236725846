import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { Toaster } from "react-hot-toast";
import { isBrowser } from "../../../utils/ssr";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { DDProJoinStateContext, DDProJoinStateProvider } from "./DDProJoinStateProvider";
import { JoinError } from "./JoinError";
import { JoinFromEmail } from "./JoinFromEmail";
import { JoinFromQRNoEmail } from "./JoinFromQRNoEmail";
import { ProJoinMachineState } from "./machine";
import { NavBar } from "./Navbar";

const DDProJoin = () => {
  if (!isBrowser()) return null;

  return (
    <DDProJoinStateProvider>
      <View />
    </DDProJoinStateProvider>
  );
};

// ts-prune-ignore-next
export default DDProJoin;

const isFromEmailSelector = (s: ProJoinMachineState) => s.matches("fromEmail");
const isFromQRSelector = (s: ProJoinMachineState) => s.matches("fromQr");
const isErrorSelector = (s: ProJoinMachineState) => s.matches("error");

const View = () => {
  const pro = useContext(DDProJoinStateContext);
  const isFromEmail = useSelector(pro.machine!, isFromEmailSelector);
  const isFromQR = useSelector(pro.machine!, isFromQRSelector);
  const isError = useSelector(pro.machine!, isErrorSelector);

  return (
    <div className="m-auto max-w-6xl ">
      <SEOReactHelmet title={`Diet Doctor - DD Pro`} />
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            fontSize: "14px",
            fontFamily: "Diet Doctor Sans, Trebuchet MS, Arial, sans-serif",
            background: "#404040",
            color: "#FFF",
          },
        }}
      />
      <NavBar />
      <div className="py-6">
        {isFromEmail && <JoinFromEmail />}
        {isError && <JoinError />}
        {isFromQR && <JoinFromQRNoEmail />}
      </div>
    </div>
  );
};
